import { db } from '@util/firebase';
import {
  collection,
  CollectionReference,
  query,
  orderBy,
  getDocs,
  where,
  limit,
} from 'firebase/firestore';
import { NextApiRequest, NextApiResponse } from 'next';
import { BrandProp } from 'pages/brands';
import { z } from 'zod';
export const brandSchema = z.object({
  id: z.string(),
  old_id: z.string(),
  name: z.string(),
  letter: z.string(),
  image: z.string().optional(),
  description: z.string().default(''),
  url: z.string(),
  meta_title: z.string().optional(),
  meta_description: z.string().optional(),
  meta_keywords: z.string().optional(),
});
export type BrandObj = z.infer<typeof brandSchema>;
const brandResponseDataSchema = z
  .object({
    imageUrl: z.string(),
  })
  .catchall(brandSchema);
export const brandResponseSchema = z.object({
  data: brandResponseDataSchema,
});
export default async function handler(
  req: NextApiRequest,
  res: NextApiResponse
) {
  const brands = await getBrands();
  res.status(200).json({ brands });
}

export async function getBrands(): Promise<BrandProp[]> {
  const ref = collection(db, 'brands') as CollectionReference<BrandObj>;
  const q = query(ref, orderBy('name'));
  //letter ? query(ref, where('letter', '==', letter), orderBy('name'))

  const snap = await getDocs(q);
  const brands = snap.docs.map((d) => d.data());
  return brands;
}

export async function getBrandBySlug(slug: string) {
  const ref = collection(db, 'brands') as CollectionReference<BrandObj>;
  const q = query(ref, where('url', '==', slug), limit(1));
  const snap = await getDocs(q);
  return snap.docs[0]?.data();
}
export async function getBrandByName(name: string) {
  const ref = collection(db, 'brands') as CollectionReference<BrandObj>;
  const q = query(ref, where('name', '==', name), limit(1));
  const snap = await getDocs(q);
  return snap.docs[0]?.data() ?? null;
}
