import { z } from 'zod';

const BaseCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  level: z.number(),
  faq: z.array(z.object({
    answer: z.string(),
    question: z.string()
  })).optional(),
  parents: z.array(z.string()).optional(),
  slug: z.string(),
  description: z.string().optional(),
  long_description: z.string().optional(),
  image: z.string().optional(),
  metadata: z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    keywords: z.string().optional()
  }),
  bottom_content: z.object({
    html: z.string().optional(),
    video: z.string().optional(),
    image: z.string().optional()
  }).optional(),  
  html: z.string().optional()
});

export const CategoryDocumentSchema = BaseCategorySchema.extend({
  sub_categories: z.array(BaseCategorySchema).optional()
});

export type CategoryDocument = z.infer<typeof CategoryDocumentSchema>;