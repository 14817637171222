import { useChat } from 'context/ChatContext';
import { useRouter } from 'next/router';

const ChatButton = ({ isSupport }: { isSupport: boolean }) => {
  const { setChatOpen, getUnreadCount } = useChat();
  const router = useRouter();
  const unreadCount = getUnreadCount();
  const handleClick = () => {
   // if (isSupport) 
    router.push('/dashboard/messages');
    //else setChatOpen((prev) => !prev);
  };
  return (
    <button
      onClick={handleClick}
      className="relative hidden min-w-[4.4rem] p-4 text-brand-black transition-colors hover:text-brand-primary md:block"
      aria-label="Chat"
    >
      {unreadCount > 0 && (
        <div className="absolute right-0 top-0 flex h-8 w-8 items-center justify-center rounded-full border-[.2rem] border-brand-white bg-brand-red text-[1.1rem] font-medium text-brand-white">
          {unreadCount}
        </div>
      )}
      <ChatIcon />
    </button>
  );
};

export default ChatButton;

const ChatIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0344 18.3656L12.6469 20.6813C12.5789 20.7918 12.4838 20.883 12.3705 20.9463C12.2573 21.0096 12.1297 21.0429 12 21.0429C11.8703 21.0429 11.7427 21.0096 11.6295 20.9463C11.5162 20.883 11.4211 20.7918 11.3531 20.6813L9.96563 18.3656C9.90037 18.2545 9.80723 18.1623 9.69542 18.0982C9.58362 18.034 9.45701 18.0002 9.32812 18H3.75C3.55109 18 3.36032 17.921 3.21967 17.7803C3.07902 17.6397 3 17.4489 3 17.25V5.25C3 5.05109 3.07902 4.86032 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H20.25C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25V17.25C21 17.4489 20.921 17.6397 20.7803 17.7803C20.6397 17.921 20.4489 18 20.25 18H14.6719C14.543 18.0002 14.4164 18.034 14.3046 18.0982C14.1928 18.1623 14.0996 18.2545 14.0344 18.3656V18.3656Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
