import { useShoppingCart } from 'context/ShoppingCartContext';
import { CartIcon } from '..';

const ShoppingCartButton = ({ displayCount }: { displayCount?: boolean }) => {
  const { getItemCount, setCartOpen } = useShoppingCart();
  const itemCount = displayCount ? getItemCount() : undefined;
  const handleClick = () => setCartOpen((prev) => !prev);
  return (
    <button
      onClick={handleClick}
      className="relative min-w-[4.4rem] p-4 text-brand-black transition-colors hover:text-brand-primary"
      aria-label="shopping-cart"
    >
      {!!itemCount && itemCount > 0 && (
        <div className="absolute right-0 top-0 flex h-8 w-8 items-center justify-center rounded-full border-[.2rem] border-brand-white bg-brand-red text-[1.1rem] font-medium text-brand-white">
          {itemCount}
        </div>
      )}
      <CartIcon />
    </button>
  );
};

export default ShoppingCartButton;
