import { SelectOption } from '@c/forms/controls/FormSelect';
import { db } from '@util/firebase';
import { getIdfromUrl } from '@util/urlHelpers';
import {
  collection,
  collectionGroup,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  Query,
  query,
  QuerySnapshot,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getStorage, uploadString, ref } from 'firebase/storage';
import { CategoryDocument } from './categories.types';

const storage = getStorage();

export const colRef = collection(
  db,
  'categories'
) as CollectionReference<CategoryDocument>;

export async function getCount(): Promise<number> {
  const col = collection(db, 'counts');
  const d = await getDoc(doc(col, 'categories'));
  return d.get('count') ?? 0;
}

export async function getCategories(max = 5) {
  const constraints = [];
  if (max) constraints.push(where("level", "<=", max));
  
  const q = query(colRef, ...constraints);
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data());
}

export async function getChildCategories(parent: string) {
  const q = query(colRef, where("parents", "array-contains", parent));
  const { docs } = await getDocs(q);
  return docs.map((doc) => doc.data());
}

export async function buildCategorySlug(categories: CategoryDocument[] | null, currentCategory: CategoryDocument) {
  let slug = currentCategory.slug;
  let parentId = currentCategory.parents && currentCategory.parents[0];

  while (parentId) {
    if (!categories) categories = await getCategories()

    const parentCategory = categories.find((cat: CategoryDocument) => cat?.id === parentId);
    if (parentCategory) {
      slug = parentCategory.slug + '/' + slug;
      parentId = parentCategory.parents && parentCategory.parents[0];
    } else {
      break;
    }
  }

  return ('/c/' + slug).toString()
}

export async function getCategoryBySlug(slug: string | null) {
  if (!slug) return null;

  const docRef = doc(colRef, slug);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data ?? null;
}

export async function getProductCategories(categories?: string[]) {
  if (!categories || categories.length === 0) {
    return [];
  }

  const categoryDocs = await Promise.all(
    categories.map(async (cat) => {
      const q = query(colRef, where("name", "==", cat));
      const snapshot = await getDocs(q);
      return snapshot.docs[0]?.data()
    })
  );

  return categoryDocs
}

export async function uploadCatImage(localUri: string, slug: string) {
  if (!localUri) return null;

  const path = `categories/${slug}-${Date.now()}`;
  const storeImageRef = ref(storage, path);
  const res = await uploadString(storeImageRef, localUri, 'data_url');
  return res.metadata.fullPath;
}

export async function setCat(cat: CategoryDocument) {
  const docRef = doc(db, 'categories', cat.id);
  await setDoc(docRef, cat);

  //increase count
  const col = collection(db, 'counts');
  await updateDoc(doc(col, 'categories'), { count: increment(1) });
}

export async function deleteCategory(cat: CategoryDocument) {
  const docRef = doc(db, 'categories', cat.id);
  await deleteDoc(docRef);
}