import Link from 'next/link';
import React from 'react';

const NavMenuSection = ({
  label,
  href,
  children,
}: {
  label: string;
  href?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex min-w-[30rem] flex-col gap-[0.4rem] px-4 break-inside-avoid pb-8">
      {href ? (
        <Link
          href={href}
          className="text-[1.6rem] font-bold hover:text-brand-secondary"
        >
          {label}
        </Link>
      ) : (
        <span className="text-[1.6rem] font-bold hover:text-brand-secondary">
          {label}
        </span>
      )}
      {children}
    </div>
  );
};

export default NavMenuSection;
