import Link from 'next/link';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

type Props = {
  children: React.ReactNode;
  href: string;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
  preserveQueryParams?: boolean;
  preservePathParams?: boolean;
  queryParam?: ParsedUrlQuery;
  noBoldTitle?: boolean;
};

const NavLink = ({
  children,
  href,
  onClick,
  preservePathParams,
  preserveQueryParams,
  queryParam,
  variant = 'primary',
  noBoldTitle,
}: Props) => {
  const router = useRouter();
  const query = queryParam ?? (preserveQueryParams ? router.query : null);
  const pathname = preservePathParams ? router.asPath + href : href;
  delete query?.categorySlug;
  const active = href ? href.endsWith(router.asPath) : false;
  const activeStyles =
    variant === 'primary'
      ? 'text-brand-black underline'
      : `text-brand-primary ${noBoldTitle ? 'font-medium' : 'font-semibold'}`;
  return (
    <Link
      className={`${noBoldTitle ? '' : 'font-medium'} transition-colors hover:text-brand-primary ${
        active ? activeStyles : 'text-brand-black'
      }`}
      href={{ pathname, query }}
      onClick={() => onClick?.()}
      prefetch={false}
    >
      {children}
    </Link>
  );
};

export default NavLink;
